* {
    padding: 0;
    margin: 0;
}

input:focus,
input:focus-visible,
select {
    border-color: #d97706 !important;
}

.todo {
    font-size: 1.5em;
    border-bottom: #fde68a 1px solid;
    margin-bottom: 10px;
    cursor: pointer;
}

.done {
    text-decoration: line-through;
    color: #b7c3bc;
}

.task-name {
    white-space: nowrap;
    overflow-x: auto;
}

.trash-icon {
    opacity: 0;
}

.todo:hover .trash-icon {
    opacity: 1;
}

.instruction {
    display: flex;
    justify-content: center;
}

.main-container {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    width: 100%;
}

.main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 800px;
    width: 70%;
    min-width: 400px;
}

.input {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    width: 100%;
    margin-bottom: 20px;
    gap: 0.5rem;
}

.input-field {
    grid-column: span 10/span 10;
}

.add-btn-container {
    grid-column: span 2/span 2;
}

.add-btn-container button {
    border-radius: 10px;
    height: 100%;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}